export default class Navigation {
  constructor(options) {
    this.main = document.getElementById(`gnav`);
    this.button = document.getElementById(`burger`);
    this.addInner();
    this.mainInner = document.getElementById(`__gnavInner`);
    this.options = {
      addClassElement: this.addClassElement || ``,
      addClassName: this.addClass || `is-open`,
      fix: this.fix || false
    };
    Object.assign(this.options, options);
    this.button.addEventListener(`click`, _event => this.clickEvent());
    this.mainInner.addEventListener(`click`, _event => this.clickEvent());
  }
  clickEvent() {
    this.isAriaExpanded(this.button);
    this.isAriaHidden(this.main);
    if (this.options.fix) {
      this.isFixOption(this.main, this.options.addClassName);
    }
    this.hasClass(this.main, this.options.addClassName);
    this.hasClass(this.mainInner, this.options.addClassName);
    if (this.options.addClassElement) {
      const el = [...document.querySelectorAll(this.options.addClassElement)];
      el.map(x => this.hasClass(x, this.options.addClassName));
    }
  }
  isAriaExpanded(target) {
    const state = target.getAttribute(`aria-expanded`);
    if (state === `false` || !target.hasAttribute(`aria-expanded`)) {
      target.setAttribute(`aria-expanded`, `true`);
    } else {
      target.setAttribute(`aria-expanded`, `false`);
    }
  }
  isAriaHidden(target) {
    const state = target.getAttribute(`aria-hidden`);
    if (state === `true` || !target.hasAttribute(`aria-hidden`)) {
      target.setAttribute(`aria-hidden`, `false`);
    } else {
      target.setAttribute(`aria-hidden`, `true`);
    }
  }
  hasClass(el, className) {
    const hasClass = el.classList.contains(className);
    if (hasClass) {
      el.classList.remove(className);
    } else {
      el.classList.add(className);
    }
  }
  isFixOption(el, className) {
    const hasClass = el.classList.contains(className);
    if (hasClass) {
      this.bodyStaticPosition();
    } else {
      this.bodyFixedPosition();
    }
  }
  bodyFixedPosition() {
    const CurrentPosition = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${CurrentPosition}px`;
    document.body.style.right = `0px`;
    document.body.style.left = `0px`;
  }
  bodyStaticPosition() {
    const GetBodyPositionTop = parseInt(document.body.style.top) * -1;
    document.body.style.position = ``;
    document.body.style.top = ``;
    document.body.style.right = ``;
    document.body.style.left = ``;
    window.scrollTo(0, GetBodyPositionTop);
  }
  addInner() {
    const inner = document.createElement(`div`);
    inner.setAttribute(`id`, `__gnavInner`);
    inner.setAttribute(`class`, `__gnavInner`);
    this.main.parentNode.insertBefore(inner, this.main.nextSibling);
  }
}
