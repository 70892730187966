export default class Hamburger {
  constructor(options) {
    this.main = document.getElementById(`burger`).querySelector(`svg`);
    this.options = {
      line: this.line || 3,
      lineWidth: this.lineWidth || 0.8,
      lineHeight: this.lineHeight || 4,
      radius: this.radius || 0,
      size: this.size || 50
    };
    Object.assign(this.options, options);
    this.setViewBox(this.main);
    this.setLineSize();
  }
  setViewBox(target) {
    target.setAttribute(`width`, this.options.size);
    target.setAttribute(`heigth`, this.options.size);
    target.setAttribute(
      `viewBox`,
      `0 0 ${this.options.size} ${this.options.size}`
    );
  }
  setLineSize() {
    const burgerLineWidth = this.options.size * this.options.lineWidth;
    const x = (100 - this.options.lineWidth * 100) / 2;
    const y = this.options.size / 2 - this.options.lineHeight / 2;
    for (let i = 1; i < this.options.line + 1; i++) {
      const rect = document.createElementNS(
        `http://www.w3.org/2000/svg`,
        `rect`
      );
      rect.setAttribute(`class`, `burger--line _line${i}`);
      rect.setAttribute(`x`, `${x}%`);
      rect.setAttribute(`y`, `${y}px`);
      rect.setAttribute(`rx`, `${this.options.radius}`);
      rect.setAttribute(`height`, `${this.options.lineHeight}px`);
      rect.setAttribute(`width`, `${burgerLineWidth}px`);
      this.main.appendChild(rect);
    }
  }
}
