import Swiper from "swiper"; //vue本体 どこでも呼び出せます

export default function topSlide() {
  const topSlider = slidePage
    ? new Swiper(".main-slider", swiperOptions)
    : false;
}
const slidePage = document.getElementById("page-home");

const swiperOptions = {
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true
  },
  autoplay: {
    delay: 4000
  }
};
