import SweetScroll from "sweet-scroll";

export default function SmoothScroll() {
  //#から始まるページ中アンカーリンクのsmoothスクロール
  const linkElement = `a[href^="#"]`;
  const smoothLink = document.querySelector(linkElement)
    ? new SweetScroll({ trigger: linkElement })
    : false;
  //TOPに戻るボタンのsmoothスクロール
  const topButton = `js-top-button`;
  const topTo = document.getElementById(topButton)
    ? new SweetScroll({ trigger: `#${topButton}` })
    : false;
}
