import 'what-input'; //キーボード操作以外でoutlineを表示させない為に必要
import UserAgent from './modules/UserAgent';
UserAgent();

//ハンバーガーのjs
import menu from './modules/menu';
menu();

// スムーズスクロールのjs
import SmoothScroll from './modules/SmoothScroll';
SmoothScroll();

// スムーズスクロールのjs
import topSlide from './modules/Swiper';
topSlide();

console.log('a');
