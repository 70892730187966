import Hamburger from "./Hamburger";
import Navigation from "./Navigation";
export default function menu() {
  const burgerOptions = {
    line: 3, //ハンバーガーの線の本数[2,3]
    lineWidth: 0.64, //ハンバーガーの横の線の長さ[0.0〜1]
    lineHeight: 3, //ハンバーガーの縦の線の長さ
    radius: 0, //ハンバーガーの横の線の角丸具合
    size: 50 //ハンバーガーのサイズ
  };
  new Hamburger(burgerOptions);
  const hamburgerOptions = {
    addClassElement: ``, //ハンバーガーを押したら開くElement
    addClassName: `is-open`, //ハンバーガーを押したら開くElementに付くclass名前
    fix: true //bodyを固定するかどうか(true,false)
  };
  new Navigation(hamburgerOptions);
  const test = [...document.querySelectorAll(".gnav_banner--link")];
  const button = document.getElementById("burger");
  test.forEach(value => {
    value.addEventListener("click", _event => {
      button.click();
    });
  });
}
